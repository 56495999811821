<template>
    <div>
        <form-element-group>
            <template v-slot:col-1>
                <form-text-field
                    ref="street"
                    :value="address.street"
                    :label="$t('applications.street-address')"
                    name="street"
                    required
                    :rules="[
                        (v) =>
                            !!v.trim() ||
                            $t('applications.street-address-required'),
                    ]"
                    :placeholder="$t('applications.enter-street-address')"
                    @blur="update('street', $event)"
                ></form-text-field>
            </template>
            <template v-slot:col-2>
                <!-- :key="
                        address.no_apt_unit
                            ? address.no_apt_unit.toString()
                            : 'unit'
                    " -->

                <div class="d-flex align-center" v-if="unit">
                    <form-text-field
                        ref="unit"
                        :value="address.unit"
                        :label="$t('applications.apt-number')"
                        name="unit"
                        :key="address.no_apt_unit ? $uuid.v4() : $uuid.v4()"
                        :disabled="address.no_apt_unit"
                        :required="!address.no_apt_unit"
                        :rules="
                            address.no_apt_unit
                                ? []
                                : [
                                      (v) =>
                                          !!v ||
                                          $t(
                                              'applications.apt-number-required'
                                          ),
                                  ]
                        "
                        :placeholder="$t('applications.enter-apt-number')"
                        @blur="update('unit', $event)"
                    ></form-text-field>
                    <v-spacer></v-spacer>
                    <v-checkbox
                        class="mt-0"
                        :true-value="true"
                        :value="address.no_apt_unit"
                        :label="$t('applications.no-apt-number')"
                        hide-details
                        dense
                        @change="update('no_apt_unit', $event)"
                    ></v-checkbox>
                </div>
            </template>
        </form-element-group>
        <form-element-group threecols>
            <template v-slot:col-1>
                <form-text-field
                    :placeholder="$t('applications.enter-city')"
                    :value="address.city"
                    :label="$t('applications.city')"
                    name="city"
                    required
                    :rules="[
                        (v) => !!v.trim() || $t('applications.city-required'),
                    ]"
                    @blur="update('city', $event)"
                ></form-text-field>
            </template>
            <template v-slot:col-2>
                <form-element label="State" required>
                    <v-select
                        :items="states"
                        ref="state"
                        item-text="name"
                        item-value="abbrev"
                        v-model="address.state"
                        :label="$t('applications.state')"
                        :rules="[
                            (v) => !!v || $t('applications.state-required'),
                        ]"
                        :dusk="address.id + '-state-input'"
                        :placeholder="$t('applications.enter-state')"
                        height="40"
                        solo
                        color="primary"
                        class="mt-2"
                        @change="update('state', $event)"
                    ></v-select>
                </form-element>
            </template>
            <template v-slot:col-3>
                <form-text-field
                    :placeholder="$t('applications.enter-zip')"
                    :value="address.zip"
                    :label="$t('applications.zip-code')"
                    name="zip"
                    required
                    :rules="[
                        (v) =>
                            !!v.trim() || $t('applications.zip-code-required'),
                    ]"
                    @blur="update('zip', $event)"
                ></form-text-field>
            </template>
        </form-element-group>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "applicant-address",
    components: {},
    props: {
        addressData: {
            type: Object,
            required: true,
        },
        unit: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            address: this.addressData,
        };
    },
    watch: {
        addressData: {
            immediate: true,
            handler(value) {
                this.addressAppend(value);
            },
        },
    },
    computed: {
        ...mapGetters("Applications", ["states"]),
    },
    methods: {
        ...mapActions("Applications", ["getStates"]),
        focus(ref) {
            if (this.$_.has(this.$refs, ref) && this.$refs[ref] !== undefined) {
                const element = this.$refs[ref].$el;
                const input = element.querySelector("input:not([type=hidden])");
                if (input) {
                    setTimeout(() => {
                        input.focus();
                    }, 0);
                }
            }
        },
        addressAppend(address) {
            // concatenate street and unit if exists into "address" param
            address["address"] = address.street;
            if (address["unit"]) {
                address["address"] += " " + address["unit"];
            }
            this.address = address;
        },

        update(key, value) {
            this.address = Object.assign(this.address, { [key]: value });
            this.addressAppend(this.address);
            this.$emit("update", this.address);
        },
    },
    mounted() {
        this.getStates();
        this.$nextTick(() => {
            this.addressData.street.length === 0
                ? this.focus("street")
                : this.focus("unit");
        });
    },
    created() {},
    updated() {},
};
</script>

<style scoped>
</style>