var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-element-group", {
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-text-field", {
                  ref: "street",
                  attrs: {
                    value: _vm.address.street,
                    label: _vm.$t("applications.street-address"),
                    name: "street",
                    required: "",
                    rules: [
                      function(v) {
                        return (
                          !!v.trim() ||
                          _vm.$t("applications.street-address-required")
                        )
                      }
                    ],
                    placeholder: _vm.$t("applications.enter-street-address")
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("street", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return [
                _vm.unit
                  ? _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c("form-text-field", {
                          key: _vm.address.no_apt_unit
                            ? _vm.$uuid.v4()
                            : _vm.$uuid.v4(),
                          ref: "unit",
                          attrs: {
                            value: _vm.address.unit,
                            label: _vm.$t("applications.apt-number"),
                            name: "unit",
                            disabled: _vm.address.no_apt_unit,
                            required: !_vm.address.no_apt_unit,
                            rules: _vm.address.no_apt_unit
                              ? []
                              : [
                                  function(v) {
                                    return (
                                      !!v ||
                                      _vm.$t("applications.apt-number-required")
                                    )
                                  }
                                ],
                            placeholder: _vm.$t("applications.enter-apt-number")
                          },
                          on: {
                            blur: function($event) {
                              return _vm.update("unit", $event)
                            }
                          }
                        }),
                        _c("v-spacer"),
                        _c("v-checkbox", {
                          staticClass: "mt-0",
                          attrs: {
                            "true-value": true,
                            value: _vm.address.no_apt_unit,
                            label: _vm.$t("applications.no-apt-number"),
                            "hide-details": "",
                            dense: ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.update("no_apt_unit", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("form-element-group", {
        attrs: { threecols: "" },
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-text-field", {
                  attrs: {
                    placeholder: _vm.$t("applications.enter-city"),
                    value: _vm.address.city,
                    label: _vm.$t("applications.city"),
                    name: "city",
                    required: "",
                    rules: [
                      function(v) {
                        return (
                          !!v.trim() || _vm.$t("applications.city-required")
                        )
                      }
                    ]
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("city", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return [
                _c(
                  "form-element",
                  { attrs: { label: "State", required: "" } },
                  [
                    _c("v-select", {
                      ref: "state",
                      staticClass: "mt-2",
                      attrs: {
                        items: _vm.states,
                        "item-text": "name",
                        "item-value": "abbrev",
                        label: _vm.$t("applications.state"),
                        rules: [
                          function(v) {
                            return !!v || _vm.$t("applications.state-required")
                          }
                        ],
                        dusk: _vm.address.id + "-state-input",
                        placeholder: _vm.$t("applications.enter-state"),
                        height: "40",
                        solo: "",
                        color: "primary"
                      },
                      on: {
                        change: function($event) {
                          return _vm.update("state", $event)
                        }
                      },
                      model: {
                        value: _vm.address.state,
                        callback: function($$v) {
                          _vm.$set(_vm.address, "state", $$v)
                        },
                        expression: "address.state"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "col-3",
            fn: function() {
              return [
                _c("form-text-field", {
                  attrs: {
                    placeholder: _vm.$t("applications.enter-zip"),
                    value: _vm.address.zip,
                    label: _vm.$t("applications.zip-code"),
                    name: "zip",
                    required: "",
                    rules: [
                      function(v) {
                        return (
                          !!v.trim() || _vm.$t("applications.zip-code-required")
                        )
                      }
                    ]
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("zip", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }